export const tealiumNavClick = (
  contactQuestion: string,
  clickText: string,
  trackTealEvent: any,
  contactDealerData: any,
  vehicleData?: any,
  zip?: number | null,
  dealerInfo?: { dealer_name: string; dealer_code: string } | null,
  errorMessage?: string | string[],
) => {
  // Selected vehicle data (contactDealerData.fromInventory) should overwrite page vehicle data (vehicleData), so it is spread _after_ vehicleData.
  contactDealerData?.fromInventory != null
    ? trackTealEvent({
        tealium_event: "contact_form_nav",
        contact_question: contactQuestion,
        dropdown_selected: clickText,
        ...contactDealerData.fromInventory,
        ...(zip && {
          customer_zip: zip,
        }),
        ...vehicleData,
        ...contactDealerData.fromInventory,
        ...contactDealerData?.allocatedDealer,
        ...contactDealerData?.dealerData,
        ...dealerInfo,
        ...(errorMessage && { error_message: errorMessage }),
      })
    : contactDealerData.fromSelection != null
      ? trackTealEvent({
          tealium_event: "contact_form_nav",
          contact_question: contactQuestion,
          dropdown_selected: clickText,
          ...contactDealerData.fromSelection,
          ...(zip && {
            customer_zip: zip,
          }),
          ...dealerInfo,
          ...(errorMessage && { error_message: errorMessage }),
        })
      : trackTealEvent({
          tealium_event: "contact_form_nav",
          contact_question: contactQuestion,
          dropdown_selected: clickText,
          ...(zip && {
            customer_zip: zip,
          }),
          ...vehicleData,
          ...dealerInfo,
          ...(errorMessage && { error_message: errorMessage }),
        })
}

// Note: `dealerInfo` should be explicitly passed into tealiumSubmit, because it's not always required even if it is present as a property of contactDealerData
export const tealiumSubmit = (
  tealiumEvent: string,
  contactQuestion: string,
  customerEmail: string,
  trackTealEvent: any,
  contactDealerData: any,
  dealerInfo?: { dealer_name: string; dealer_code: string },
  moreData?: {},
  errorMessage?: string | string[],
) => {
  contactDealerData?.fromInventory != null
    ? trackTealEvent({
        tealium_event: tealiumEvent,
        contact_question: contactQuestion,
        customer_email: customerEmail,
        ...contactDealerData.fromInventory,
        ...((tealiumEvent == "thank_you_page" ||
          tealiumEvent == "error_page") &&
          contactDealerData.allocatedDealer),
        ...contactDealerData.dealerData,
        ...contactDealerData?.allocatedDealer,
        ...dealerInfo,
        ...(errorMessage && { error_message: errorMessage }),
      })
    : contactDealerData.fromSelection != null
      ? trackTealEvent({
          tealium_event: tealiumEvent,
          contact_question: contactQuestion,
          customer_email: customerEmail,
          ...contactDealerData.fromSelection,
          ...dealerInfo,
          ...moreData,
        })
      : trackTealEvent({
            tealium_event: tealiumEvent,
            contact_question: contactQuestion,
            customer_email: customerEmail,
            ...dealerInfo,
            ...moreData,
          })
        ? trackTealEvent({
            tealium_event: tealiumEvent,
            contact_question: contactQuestion,
            customer_email: customerEmail,
            ...contactDealerData.fromSelection,
            ...dealerInfo,
            ...moreData,
            ...(errorMessage && { error_message: errorMessage }),
          })
        : trackTealEvent({
            tealium_event: tealiumEvent,
            contact_question: contactQuestion,
            customer_email: customerEmail,
            ...dealerInfo,
            ...moreData,
            ...(errorMessage && { error_message: errorMessage }),
          })
}
