import React, { useContext, useEffect, useState } from "react"
import { ContactContext } from "../../../contexts/Contact"
import { LanguageContext } from "../../../contexts/Language"
import { ViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import tw from "twin.macro"
import { setActiveView, updateField } from "../../../contexts/Contact/actions"
import CheckboxField from "./CheckboxField"
import ValidationPopover from "./ValidationPopover"
import { useTealiumContext } from "../../../contexts/Tealium"
import { firstLettersCapital, toUsd } from "../../../helpers"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { tealiumNavClick } from "./TealiumHelpers"

const SelectFavoriteVehiclesView: React.FC<ViewProps> = ({ active }) => {
  const [{ favoriteVehicles, seriesName }, dispatch] =
    useContext(ContactContext)
  const { _ } = useContext(LanguageContext)

  const [favoriteTrims, setFavoriteTrims] = useState<string[]>([])
  const [selectedTrims, setSelectedTrims] = useState([])
  const [showRequiredValidatioMsg, setShowRequiredValidatioMsg] =
    useState(false)
  const commentTranslation = _("I'm interested in the ")

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData, updateContactDealerData } = useTealiumContext()

  useEffect(() => {
    if (!favoriteVehicles || !Array.isArray(favoriteVehicles)) return

    // Only showing unique trim checkboxes
    const vehicleDescriptions = favoriteVehicles
      ?.filter(
        vehicle => vehicle.series?.toLowerCase() === seriesName?.toLowerCase(),
      )
      ?.map(vehicle => vehicle.description)
    const uniqueVehicleDescriptions = [...new Set(vehicleDescriptions)]
    setFavoriteTrims(uniqueVehicleDescriptions)
    setSelectedTrims([])
  }, [seriesName])

  // Remove validation messaging when user selects a trim
  useEffect(() => {
    if (selectedTrims?.length > 0) {
      setShowRequiredValidatioMsg(false)
    }
  }, [selectedTrims])

  const handleNextOnClick = () => {
    if (selectedTrims?.length === 0) {
      setShowRequiredValidatioMsg(true)
    } else {
      let comment = commentTranslation
      let tealVehicleModel: string[] = [],
        tealVehicleExteriorColor: string[] = [],
        tealVehicleYear: string[] = [],
        tealVehicleInteriorColor: string[] = [],
        tealVehicleTrim: string[] = [],
        tealVehicleTrimId: string[] = [],
        tealVehicleVin: string[] = [],
        tealVehicleSalesPrice: string[] = [],
        selectedGrades: string[] = [],
        allocatedDealerNames: string[] = [],
        allocatedDealerCodes: string[] = []

      selectedTrims?.forEach((trim, i) => {
        // including all favorited vins for each selected vehicle trim
        favoriteVehicles?.forEach(vehicle => {
          if (vehicle?.description === trim) {
            if (comment?.includes(trim)) {
              comment += `, VIN # ${vehicle?.vin}`
            } else {
              comment += `${vehicle?.description}, VIN #${vehicle?.vin}`
            }
            selectedGrades.push(vehicle.grade)
            // Tealium
            tealVehicleModel.push(vehicle.series)
            tealVehicleExteriorColor.push(vehicle.color.exterior.name)
            tealVehicleYear.push(vehicle.year)
            tealVehicleInteriorColor.push(vehicle.color.interior.name)
            tealVehicleTrim.push(vehicle.grade)
            tealVehicleTrimId.push(vehicle.modelId?.toString())
            tealVehicleVin.push(vehicle.vin)
            tealVehicleSalesPrice.push(`$${toUsd(vehicle.msrp)}`)
            allocatedDealerNames.push(firstLettersCapital(vehicle.dealerName))
            allocatedDealerCodes.push(vehicle.dealer)
          }
        })
        if (selectedTrims.length - 1 > i) {
          comment += ", and the "
        }
      })
      dispatch(updateField("comments", comment))
      dispatch(setActiveView("NameView"))
      if (selectedGrades?.length === 1) {
        dispatch(updateField("trim", selectedGrades[0]))
      }

      // Tealium
      updateContactDealerData({
        fromInventory: {
          vehicle_model: tealVehicleModel,
          vehicle_exterior_color: tealVehicleExteriorColor,
          vehicle_year: tealVehicleYear,
          vehicle_interior_color: tealVehicleInteriorColor,
          vehicle_trim: tealVehicleTrim,
          vehicle_trim_id: tealVehicleTrimId,
          vehicle_vin: tealVehicleVin,
          vehicle_sale_price: tealVehicleSalesPrice,
        },
        allocatedDealer: {
          allocated_dealer_name: allocatedDealerNames,
          allocated_dealer_code: allocatedDealerCodes,
        },
      })
      tealiumNavClick(
        "Select the trims you wish to share.",
        "Next",
        trackTealEvent,
        {
          fromInventory: {
            vehicle_model: tealVehicleModel,
            vehicle_exterior_color: tealVehicleExteriorColor,
            vehicle_year: tealVehicleYear,
            vehicle_interior_color: tealVehicleInteriorColor,
            vehicle_trim: tealVehicleTrim,
            vehicle_trim_id: tealVehicleTrimId,
            vehicle_vin: tealVehicleVin,
            vehicle_sale_price: tealVehicleSalesPrice,
          },
        },
      )
    }
  }

  return (
    <FormPage
      active={active}
      heading={_("Select the trims you wish to share.")}
      prevOnClick={() => {
        dispatch(setActiveView("SelectFavoriteSeriesView"))
        tealiumNavClick(
          "Select the trims you wish to share.",
          "Back",
          trackTealEvent,
          contactDealerData,
        )
      }}
      nextOnClick={handleNextOnClick}
    >
      {favoriteTrims?.length > 0 && (
        <fieldset css={tw`my-8 flex flex-wrap justify-center gap-2`}>
          {favoriteTrims?.map(trim => (
            <CheckboxField
              label={trim}
              value={trim}
              checked={selectedTrims?.includes(trim)}
              handleCheck={value => setSelectedTrims([...selectedTrims, value])}
              handleUncheck={value => {
                const updatedFavorites = selectedTrims?.filter(
                  favorite => favorite !== value,
                )
                setSelectedTrims(updatedFavorites)
              }}
            />
          ))}
        </fieldset>
      )}
      {/* At least one selection required validation popover */}
      <ValidationPopover
        showRequiredValidatioMsg={showRequiredValidatioMsg}
        message={_("Please select a trim that you'd like to share.")}
      />
    </FormPage>
  )
}

export default SelectFavoriteVehiclesView
