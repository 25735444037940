import React, { useContext, useEffect, useState } from "react"
import { ContactContext } from "../../../contexts/Contact"
import { LanguageContext } from "../../../contexts/Language"
import { ViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import tw from "twin.macro"
import { setActiveView, updateField } from "../../../contexts/Contact/actions"
import ValidationPopover from "./ValidationPopover"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { tealiumNavClick } from "./TealiumHelpers"
import RadioButton from "./RadioButton"

const SelectFavoriteSeriesView: React.FC<ViewProps> = ({ active }) => {
  const [{ favoriteVehicles, seriesName }, dispatch] =
    useContext(ContactContext)
  const { _ } = useContext(LanguageContext)
  const [favoriteSeries, setFavoriteSeries] = useState([])
  const [showRequiredValidatioMsg, setShowRequiredValidatioMsg] =
    useState(false)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData, updateContactDealerData } = useTealiumContext()

  useEffect(() => {
    if (!favoriteVehicles || !Array.isArray(favoriteVehicles)) return

    // Getting favorite vehicles using favorited vins
    const vehicleSeries = favoriteVehicles?.map(vehicle => vehicle.series)
    const uniqueVehicleSeries = [...new Set(vehicleSeries)]
    setFavoriteSeries(uniqueVehicleSeries)
  }, [favoriteVehicles])

  // Remove validation messaging when user selects a trim
  useEffect(() => {
    if (favoriteSeries?.length > 0) {
      setShowRequiredValidatioMsg(false)
    }
  }, [])

  const handleNextOnClick = () => {
    // Tealium
    tealiumNavClick(
      "Select the model you wish to share.",
      "Next",
      trackTealEvent,
      contactDealerData,
    )

    if (!seriesName) {
      setShowRequiredValidatioMsg(true)
    } else {
      dispatch(setActiveView("SelectFavoriteVehiclesView"))
    }
  }

  return (
    <FormPage
      active={active}
      heading={_("Select the model you wish to share.")}
      prevOnClick={() => {
        dispatch(setActiveView("ShareFavoritesView"))
        tealiumNavClick(
          "Select the model you wish to share.",
          "Back",
          trackTealEvent,
          contactDealerData,
        )
      }}
      nextOnClick={handleNextOnClick}
    >
      {favoriteSeries?.length > 0 && (
        <fieldset css={tw`my-8 flex flex-wrap justify-center gap-2`}>
          {favoriteSeries?.map(series => (
            <RadioButton
              label={series}
              value={series}
              id={series}
              name="favoriteSeries"
              handleChange={(value: string): void => {
                dispatch(updateField("seriesName", value))
              }}
            />
          ))}
        </fieldset>
      )}
      {/* Series selection required validation popover */}
      <ValidationPopover
        showRequiredValidatioMsg={showRequiredValidatioMsg}
        message={_("Please select a model that you'd like to share.")}
      />
    </FormPage>
  )
}

export default SelectFavoriteSeriesView
